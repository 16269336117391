/**
 * The function sanitizes URL against the DOM-based Cross-Site Scripting attacks
 *
 * @export
 * @param {(string | null | undefined)} url
 * @returns {*}
 */
export function sanitizeUrl(url: string | null | undefined) {
  if (url === null) return null;
  if (url === undefined) return undefined;
  if (url.length === 0) return url;

  try {
    const parsedUrl = new URL(url);
    if (parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:") {
      return encodeURI(url);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Invalid URL provided: ${e}`);
  }

  return "#";
}
