import { z } from "zod";
import { sanitizeUrl } from "./sanitizeUrl";

const urlSchema = z.string().url();

/**
 * Validates if the provided URL is valid.
 * Throws an error if the URL is invalid.
 *
 * @param url - The URL string to validate.
 * @throws {Error} - Throws an error if the URL is invalid.
 * @returns {string} - The validated and standardized URL string.
 */
/**
 * Validates and sanitizes the provided URL.
 * Throws an error if the URL is invalid or potentially unsafe.
 *
 * @param url - The URL string to validate and sanitize.
 * @throws {Error} - Throws an error if the URL is invalid or unsafe.
 * @returns {string} - The validated and sanitized URL string.
 */
export function setValidUrl(url: string): string {
  const sanitizedUrl = sanitizeUrl(url);

  if (
    sanitizedUrl === "#" ||
    sanitizedUrl === null ||
    sanitizedUrl === undefined
  ) {
    throw new Error(`Invalid or unsafe URL: ${url}`);
  }

  const result = urlSchema.safeParse(sanitizedUrl);

  if (!result.success) {
    throw new Error(`Invalid URL: ${url}. ${result.error.message}`);
  }

  return new URL(sanitizedUrl).toString();
}
