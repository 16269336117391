"use client";

import { SWRConfig } from "swr";
import { ReactNode } from "react";
import fetcher from "@/services/fetcher";

export default function SWRProvider({ children }: { children: ReactNode }) {
  return (
    <SWRConfig
      value={{
        fetcher,
      }}
    >
      {children}
    </SWRConfig>
  );
}
