import { setValidUrl } from "@/lib/utils/url/setValidUrl";
import { getMinuteTimestamp } from "@/lib/utils/date/getMinuteTimestamp";

/**
 * Appends a timestamp query parameter to the given URL.
 *
 * This function checks if the URL already has query parameters.
 * If it does, it appends the timestamp using an ampersand (&);
 * otherwise, it adds it using a question mark (?).
 *
 * The resulting URL is then validated using the `setValidUrl` function.
 *
 * @param {string} url - The URL to which the timestamp should be added.
 * @returns {string} - The updated URL with the timestamp query parameter.
 * @throws {Error} - Throws an error if the resulting URL is invalid.
 */
export function setTimestampUrl(url: string) {
  const timestamp = getMinuteTimestamp();
  const urlWithTimestamp = `${url}${url.includes("?") ? "&" : "?"}tid=${timestamp}`;
  const timestampUrl = setValidUrl(urlWithTimestamp);

  return timestampUrl;
}
