/**
 * Returns the current timestamp rounded to the nearest minute.
 *
 * This function creates a `Date` object for the current time,
 * then sets the seconds and milliseconds to zero, effectively rounding
 * the time down to the nearest minute. It then returns the Unix timestamp
 * (milliseconds since January 1, 1970) for that time.
 *
 * @returns {number} The Unix timestamp in milliseconds for the current time rounded to the nearest minute.
 */
export function getMinuteTimestamp() {
  const now = new Date();
  now.setSeconds(0, 0);
  return now.getTime();
}
