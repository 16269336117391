"use client";

import { useEffect } from "react";

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    chatwootSettings: {
      hideMessageBubble: boolean;
      position: "left" | "right";
      locale: "en" | "ru";
      type: "standard" | "expanded_bubble";
    };
    chatwootSDK: {
      // eslint-disable-next-line no-unused-vars
      run: (settings: { websiteToken: string; baseUrl: string }) => void;
    };
  }
}

interface ChatwootWidgetProps {
  locale: "en" | "ru";
}

const ChatwootWidget = ({ locale }: ChatwootWidgetProps) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.chatwootSettings = {
        hideMessageBubble: false,
        position: "right",
        locale,
        type: "standard",
      };

      const BASE_URL = "https://app.chatwoot.com";
      const scriptId = "chatwoot-script";

      const initializeChatwoot = () => {
        if (window.chatwootSDK) {
          window.chatwootSDK.run({
            websiteToken: "4aaSFsK3M8HpSbWJPc2r624M",
            baseUrl: BASE_URL,
          });
        } else {
          // console.error("Chatwoot SDK is not loaded");
        }
      };

      const loadChatwootScript = (retries: number = 3) => {
        if (document.getElementById(scriptId)) {
          initializeChatwoot();
          return;
        }

        const script = document.createElement("script");
        script.id = scriptId;
        script.src = `${BASE_URL}/packs/js/sdk.js`;
        script.async = true;

        script.onload = initializeChatwoot;

        script.onerror = () => {
          if (retries > 0) {
            // console.error(
            //   `Chatwoot script failed to load. Retrying... (${retries} attempts left)`,
            // );
            setTimeout(() => loadChatwootScript(retries - 1), 2000);
          } else {
            // console.error(
            //   "Failed to load Chatwoot script after multiple attempts.",
            // );
          }
        };

        document.head.appendChild(script);
      };

      loadChatwootScript();

      const handleRouteChange = () => {
        if (window.chatwootSDK) {
          initializeChatwoot();
        } else {
          loadChatwootScript();
        }
      };

      window.addEventListener("popstate", handleRouteChange);
      window.addEventListener("pushState", handleRouteChange);

      return () => {
        window.removeEventListener("popstate", handleRouteChange);
        window.removeEventListener("pushState", handleRouteChange);
      };
    }
  }, [locale]);

  return null;
};

export default ChatwootWidget;
