import { setTimestampUrl } from "@/lib/utils/url/setTimestampUrl";

/**
 * Constructs the API URL for a given endpoint, appending a timestamp as a query parameter.
 *
 * @param {string} endpoint - The API endpoint to construct the URL for.
 * @returns {string} The constructed API URL with a timestamp.
 *
 * @example
 * // Assuming NEXT_PUBLIC_API_BASE_URL is set to "https://api.example.com"
 * const url = constructApiUrl("users");
 * console.log(url); // "https://api.example.com/en/users?timestamp=1630546800000"
 *
 * @example
 * // If NEXT_PUBLIC_API_BASE_URL is not set
 * const url = constructApiUrl("products");
 * console.log(url); // "http://localhost:3000/en/deposits?timestamp=1630546800000"
 */
export function constructApiUrl(endpoint: string) {
  const baseUrl =
    process.env.NEXT_PUBLIC_API_BASE_URL ?? "http://localhost:3000";
  const apiUrl = setTimestampUrl(`${baseUrl}/${endpoint}`);

  return apiUrl;
}
