"use client";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useSession } from "next-auth/react";
import { useUser } from "@/services/queries";

export default function UserButton() {
  const { data: session } = useSession();
  const { data: userData } = useUser(session?.user?.sub as string);

  const user = userData?.data;

  const avatarUser = user?.image;
  const avatarFallback = user?.name?.charAt(0).toUpperCase();

  return (
    <div className="flex items-center">
      <div className="relative inline-block text-left bg-block rounded-full">
        <Avatar>
          <AvatarImage
            className="object-cover"
            src={avatarUser as string | undefined}
          />
          <AvatarFallback className="text-lg leading-[1.125rem]">
            {avatarFallback
              ? avatarFallback
              : session?.user?.name?.charAt(0).toUpperCase()}
          </AvatarFallback>
        </Avatar>
      </div>
    </div>
  );
}
