"use client";

import { usePathname, useRouter } from "next/navigation";
import React, { useTransition } from "react";
import { useLocale } from "next-intl";
import { cn } from "@/lib/utils";

const LANGUAGES = [
  { code: "ru", label: "RU" },
  { code: "en", label: "EN" },
  { code: "tr", label: "TR" },
];

export default function LanguageToggle() {
  const [isPending, startTransition] = useTransition(); // eslint-disable-line no-unused-vars
  const router = useRouter();
  const pathname = usePathname();

  /// TODO: Use mapping instead of hardcoded ru|en when required

  const relativePath = pathname.replace(/^\/(ru|en|tr)/, "");
  const activeLocale = useLocale();

  const changeLanguage = (lang: string) => {
    if (lang !== activeLocale) {
      startTransition(() => {
        router.replace(`/${lang}${relativePath}`);
      });
    }
  };

  return (
    <div className="flex items-center gap-x-1">
      {LANGUAGES.map((lang, index) => (
        <React.Fragment key={lang.code}>
          {index > 0 && <span className="text-muted">|</span>}
          <span
            className={cn(
              "cursor-pointer",
              activeLocale === lang.code ? "text-primary" : "text-muted"
            )}
            onClick={() => changeLanguage(lang.code)}
          >
            {lang.label}
          </span>
        </React.Fragment>
      ))}
    </div>
  );
}
