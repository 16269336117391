import { isDevEnv } from "@/lib/utils/security/isDevEnv";
import { constructApiUrl } from "@/lib/utils/url/constructApiUrl";
import axios from "axios";

const baseURL = process.env.NEXT_PUBLIC_API_BASE_URL;

export const axiosInstance = axios.create({
  baseURL: baseURL ?? "/api",
  headers: {
    "Cache-Control": "no-store", // prevent caching for all requests
  },
});

/* eslint-disable no-console */
// TODO: add logging/performance decorators
export default async function fetcher<T = any>(
  endpoint: string,
  config?: RequestInit
): Promise<T> {
  const apiUrl = constructApiUrl(endpoint);

  const requestConfig: RequestInit = {
    cache: "no-store",
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
    ...config,
  };

  if (isDevEnv()) {
    console.debug(
      `[Fetcher] ${apiUrl} Config: ${JSON.stringify(requestConfig)}`
    );
    console.time(`[Fetcher] Fetching ${apiUrl}`);
  }

  const response = await fetch(`${apiUrl}`, requestConfig);

  if (isDevEnv()) {
    console.timeEnd(`[Fetcher] Fetching ${apiUrl}`);
  }

  return await response.json();
}
/* eslint-enable no-console */
