"use client";

import { UserMetrics, GlobalMetrics } from "@/types/metrics";
import {
  Account,
  Deposit,
  FirstLevelReferral,
  ReferralWithEarnings,
  SecondLevelReferral,
  ThirdLevelReferral,
  Transaction,
  ReferralLink,
  ExtendedTransaction,
} from "@/types/index";
import { UserDiscountRequirements } from "@/types/users";
import { BasicUser } from "@/types/common";
import { RichWithdrawalRequest } from "@/types/withdrawal";
import useSWR from "swr";
import { ExtendedDeposit } from "@/types/deposits";
import { Promocode as DbPromocode } from "@/types/promocode";
import { ApiDeposit, ApiReferral, ApiUser } from "@/types/api";

export function useUsers() {
  return useSWR<ApiUser[]>(`users`);
}

export function useMe() {
  return useSWR<BasicUser>("me");
}

export function useUser(id: string | null | undefined) {
  return useSWR<{
    data: BasicUser & {
      referral_link: ReferralLink;
      accounts: Account[];
      transactions: Transaction[];
      deposits: Deposit[];
    };
  }>(id ? `users/${id}` : null);
}

export function useInviter(id: string | null | undefined) {
  return useSWR<{ email?: string; error?: string }>(
    id ? `inviters/${id}` : null
  );
}

export function useUserBalance(id: string) {
  return useSWR<{ balance: number }>(id ? `users/${id}/balance` : null);
}

export function useReferral(id: string) {
  return useSWR<ApiReferral>(id ? `referrals/${id}` : null);
}

export type HostedReferralsResponse = {
  referrals: ReferralWithEarnings[];
  firstLevelReferrals: FirstLevelReferral[];
  secondLevelReferrals: SecondLevelReferral[];
  thirdLevelReferrals: ThirdLevelReferral[];
  referralStats: {
    total: number;
    thisMonthTotal: number;
    thisMonthFirstLevel: number;
    thisMonthSecondLevel: number;
    thisMonthThirdLevel: number;
  };
  earningsStats: {
    total: number;
    thisMonth: number;
    totalFirstLevel: number;
    totalSecondLevel: number;
    totalThirdLevel: number;
  };
};
export function useHostedReferrals(id: string) {
  return useSWR<HostedReferralsResponse>(id ? `referrals/host/${id}` : null);
}

export function useDeposits() {
  // TODO: update to ApiDeposit[]
  return useSWR<ExtendedDeposit[]>("deposits");
}

export function useUserDeposits(id: string) {
  return useSWR<ApiDeposit[]>(id ? `users/${id}/deposits` : null);
}

export function useTransactions() {
  return useSWR<ExtendedTransaction[]>("transactions");
}

export function useUserTransactions(id: string) {
  return useSWR<ExtendedTransaction[]>(id ? `users/${id}/transactions` : null);
}

export function useUserDiscountRequirements(id: string) {
  return useSWR<UserDiscountRequirements>(
    id ? `users/${id}/discount-requirements` : null
  );
}

export function useWithdrawalRequests() {
  return useSWR<RichWithdrawalRequest[]>("withdrawals");
}

export function useWithdrawalsCount(isAdmin: boolean) {
  return useSWR<number>(isAdmin ? "withdrawals/count" : null);
}

export function useUserMetrics(id: string) {
  return useSWR<UserMetrics>(id ? `snapshots/user/${id}` : null);
}

export function useGlobalMetrics() {
  return useSWR<GlobalMetrics>(`snapshots/global`);
}

export function usePromocodes() {
  return useSWR<DbPromocode[]>(`promocodes`);
}
